.ETA {
  margin: 10px;
  padding: 20px;
  background-color: #a61009;
  color: white;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: center;
  border-radius: 20px;
}
